import turtleicon from "../../utils/images/turtleicon.png"

type I_PROPS = {
  message?: string
}

const WaitingComponent = ({ message = "loading ..." }: I_PROPS) => {
  return (
    <div className="w-fit mx-auto mt-5 text-center">
      <img className="w-40" src={turtleicon} />
      <p className="ml-1 font-bold">
        {message}
      </p>
    </div>
  )
}

export default WaitingComponent