import { useNavigate } from "react-router-dom"
import Tooltip from "../Tooltip"
import GoBackButton from "../GoBackButton"

interface I_PROPS {
  headerInformationKeys: string[]
  headerInformationValues: string[]
}

export default function PageHeader({
  headerInformationKeys,
  headerInformationValues,
}: I_PROPS) {
  const navigate = useNavigate()
  return (
    <header className="flex flex-col px-4 py-8 gap-6 font-mono">
      <GoBackButton />
      <Tooltip keys={headerInformationKeys} values={headerInformationValues} />
    </header>
  )
}
