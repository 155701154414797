import React from "react"
import hanburgerLogo from "../../utils/images/icons-menu.svg"
import { useState } from "react"

import Sidebar from "../Sidebar"

const HamburgerMenu = () => {
  const [visible, setVisible] = useState(false)

  return (
    <div className="mt-1 block tablet:hidden cursor-pointer">
      <div onClick={() => setVisible(!visible)}>
        <img src={hanburgerLogo} alt="Menu" />
      </div>
      {visible && (
        <div
          className="w-screen h-full top-0 left-0 z-10 bg-black bg-opacity-25 absolute"
          onClick={() => setVisible(false)}
        >
          <div className="" onClick={(e) => e.stopPropagation()}>
            <Sidebar
              active={0}
              hamburger
              handleCloseClick={() => setVisible(false)}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default HamburgerMenu
