import React from "react"
import { useEffect } from "react"
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom"
import { Router, Routes, Route } from "react-router-dom"

import Layout from "./pages/Layout"

import TraderProfile from "./components/TraderProfile"
import ShareProfile from "./components/ShareProfile"
import TradeResultProfile from "./components/TradeResultProfile"
import ProtocolProfile from "./components/ProtocolProfile"
import TopTraders from "./components/TopTraders"
import TopShares from "./components/TopShares"
import TablePage from "./components/TablePage"

import getMostValShares from "./requests/getMostValShares"
import getMostProfitedTraders from "./requests/getMostProfitedTraders"
import { viewSelf, viewEther, viewSupplyPrice } from "./utils/helper"

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout active={1} Component={<ProtocolProfile />} />,
    },
    {
      path: "/trader/:traderAddress",
      element: <Layout active={2} Component={<TraderProfile />} />,
    },
    {
      path: "/trader",
      element: <Layout active={2} Component={<TraderProfile />} />,
    },
    {
      path: "/traderesult/:id/:shareaddress",
      element: <Layout active={2} Component={<TradeResultProfile />} />,
    },
    {
      path: "/share/:shareAddress",
      element: <Layout active={3} Component={<ShareProfile />} />,
    },
    {
      path: "/share",
      element: <Layout active={3} Component={<ShareProfile />} />,
    },
    {
      path: "/topshares",
      element: <Layout active={4} Component={<TopShares />} />,
    },
    {
      path: "/toptraders",
      element: <Layout active={5} Component={<TopTraders />} />,
    },
    {
      path: "/mostvalshares",
      element: <Layout active={0} Component={
        <TablePage
          name="Most Valuable Shares"
          dataCall={getMostValShares}
          columns={["address", "supply", "ether_in", "trade_count"]}
          columnHeaders={["Share", "Price", "Ether In", "Trades"]}
          columnViewers={[viewSelf, viewSupplyPrice, viewEther, viewSelf]}
          itemName="share"
        />
      } />,
    },
    {
      path: "/mostprofited",
      element: <Layout active={0} Component={
        <TablePage
          name="Most Profited Traders"
          dataCall={getMostProfitedTraders}
          columns={[
            "address",
            "total_eth_profit",
            "trade_result_count",
            "success_count",
          ]}
          columnHeaders={["Trader", "Profit", "Traded Shares", "Success Count"]}
          columnViewers={[viewSelf, viewEther, viewSelf, viewSelf]}
          itemName="trader"
        />
      } />,
    },
  ])

  return <RouterProvider router={router} />
}

export default App
