import PhotoSlider from "../PhotoSlider"

const RightSideBar = () => {
  return (
    <div className="flex flex-col justify-between w-80 h-screen fixed right-0 shadow-lg px-5 py-5 space-y-4 hidden desktop:flex">
      <div className="mt-20">
        <PhotoSlider />
      </div>
      <div className="p-4 text-center font-mono shadow-2xl border-green-600 rounded-lg aspect-[4/3]">
        <div className="font-bold text-2xl text-green-800 mt-5">Email Us</div>
        <div className="mt-5 text-xs">
          Email us for feedback and partnership opportunities.
        </div>
        <div className="font-sans mt-5">contact@turtleshack.xyz</div>
      </div>
    </div>
  )
}

export default RightSideBar
