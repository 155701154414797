import { useState, useEffect } from "react"
import { ethers } from "ethers"
import getShare from "../../requests/getShare"

import SearchBoxHeader from "../SearchBoxHeader"
import BrushChart from "../Charts/BrushChart"
import WaitingComponent from "../WaitingComponent"
import LargeInfoBox from "../LargeInfoBox"
import InfoBox from "../InfoBox"
import { viewEther, viewSmallEther, viewSupplyPrice } from "../../utils/helper"
import { getPrice } from "../../utils/helper"
import { useParams, useNavigate } from "react-router-dom"

import useWindowDimensions from "../../hooks/useWindowDimensions"

const ShareProfile = () => {
  let emptyShare = {
    address: "",
    ether_in: "",
    history_blocks: [],
    history_supply: [],
    supply: 0,
    trade_count: 0,
    trader_count: 0,
    volume: "",
  }
  const [share, setShare] = useState<Share>(emptyShare)

  const [graphWidth, setGRaphWidth] = useState(1000)
  const [graphHeight, setGRaphHeight] = useState(500)

  const { width, height } = useWindowDimensions()

  const { shareAddress } = useParams()

  const navigate = useNavigate()

  const handleSearch = async (shareAddress: string) => {
    let lowerAddress = shareAddress.toLowerCase()

    if (!ethers.utils.isAddress(lowerAddress)) {
      console.log("not address")
      return
    }

    navigate(`/share/${lowerAddress}`)
  }

  const handleGetShare = async (address: string) => {
    try {
      let res = await getShare(address)

      console.log(res)

      setShare(res.result || emptyShare)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetShare(shareAddress || "")
  }, [shareAddress])

  useEffect(() => {
    if (width > 1415) {
      setGRaphWidth(1000)
      setGRaphHeight(500)
    } else if (width > 1200) {
      setGRaphWidth(800)
      setGRaphHeight(400)
    } else if (width > 750) {
      setGRaphWidth(600)
      setGRaphHeight(400)
    } else if (width > 470) {
      setGRaphWidth(400)
      setGRaphHeight(300)
    } else {
      setGRaphWidth(330)
      setGRaphHeight(300)
    }
  }, [width])

  return (
    <div className="block p-00 bigmobile:p-4 mdscreen:p-8 mx-auto laptop:w-[68rem] mdscreen:ml-80 desktop:mr-80">
      <SearchBoxHeader
        handleSearch={handleSearch}
        header="Share"
        explanation={share.address}
        expMobile={share.address.slice(0, 12) + "..." + share.address.slice(-7)}
      />
      {share.address ? (
        <>
          <div className="mt-5 w-fit h-fit mx-auto">
            <BrushChart
              width={graphWidth}
              height={graphHeight}
              historyBlocks={share.history_blocks}
              historyProfit={share.history_supply.map((s) =>
                getPrice(s, 1).toString()
              )}
            />
          </div>

          <div className="bigmobile:grid grid-cols-7 gap-4 w-9/12 mx-auto">
            <LargeInfoBox
              name="Total ETH In"
              value={viewEther(share.ether_in)}
            />
            <InfoBox
              name="Price ETH"
              value={viewSupplyPrice(share.supply)}
            />
            <InfoBox
              name="Volume ETH"
              value={viewEther(share.volume)}
            />
          </div>
        </>
      ) : (
        <WaitingComponent
          message="enter a share address"
        />
      )}
    </div>
  )
}

export default ShareProfile
