import React from "react"
import { useState, useEffect } from "react"
import getTrader from "../../requests/getTrader"

export type SearchboxProps = {
  handleSearch: any
  placeHolder?: string
}

const Searchbox = ({ handleSearch, placeHolder }: SearchboxProps) => {
  const [input, setInput] = useState("")

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch(input).then(() => {
      console.log("handled")
      setInput("")
    })
  }

  return (
    <div>
      <form className="relative" onSubmit={(e) => handleSubmit(e)}>
        <input
          type="search"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="block w-full p-3 pl-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-green-500 focus:border-green-500"
          placeholder={
            placeHolder ? placeHolder : "Search Addresses ex. 0x07bea07..."
          }
        />
        <button
          type="submit"
          className="text-white absolute right-2.5 bottom-1.5 bg-green-700 hover:bg-green-800 focus:ring-1 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2"
        >
          Search
        </button>
      </form>
    </div>
  )
}

export default Searchbox
