import React from "react"
import { useState, useEffect } from "react"
import SearchBoxHeader from "../SearchBoxHeader"
import { ethers } from "ethers"
import { useNavigate } from "react-router-dom"
import getTopShares from "../../requests/getTopShares"
import { supplyToEthPrice } from "../../utils/helper"
import Table from "../Table"
import { viewEther, viewSelf, viewSupplyPrice } from "../../utils/helper"
import WaitingComponent from "../WaitingComponent"

const TopShares = () => {
  const [mostValShares, setValShares] = useState<ShareItem[]>([])

  const navigate = useNavigate()

  const handleSearch = async (shareAddress: string) => {
    let lowerAddress = shareAddress.toLowerCase()

    if (!ethers.utils.isAddress(lowerAddress)) {
      console.log("not address")
      return
    }

    navigate(`/share/${lowerAddress}`)
  }

  const handleVisit = (e: any, itemAddress: string, itemUrl: string) => {
    e.preventDefault()

    navigate(`/${itemUrl}/${itemAddress}`)
  }

  const getPage = async () => {
    let res = await getTopShares()

    setValShares(res.most_val_shares)
  }

  useEffect(() => {
    getPage()
  }, [])

  return (
    <div className="block p-00 bigmobile:p-4 mdscreen:p-8 mx-auto laptop:w-[68rem] mdscreen:ml-80 desktop:mr-80">
      <SearchBoxHeader
        handleSearch={handleSearch}
        header="Top Shares"
        placeHolder="Search a share subject ex. 0xabcdef..."
        explanation={""}
        expMobile={""}
      />
      {
        mostValShares.length > 0 ?
          <>
            <Table
              header="Most Valueable Shares"
              data={mostValShares}
              columns={["address", "supply", "ether_in", "trade_count"]}
              columnHeaders={["Share", "Profit", "Ether In", "Trades"]}
              columnViewers={[viewSelf, viewSupplyPrice, viewEther, viewSelf]}
              itemName="share"
              handleVisit={handleVisit}
              viewMore
              to={"/mostvalshares"}
            />
          </>
          :
          <WaitingComponent />
      }

    </div>
  )
}

export default TopShares
