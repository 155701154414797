import { Link } from "react-router-dom"

interface props {
  name: string
  icon: JSX.Element
  message?: string
  disabled?: boolean
  to: string
  active?: boolean
}

const SidebarItem = ({
  name,
  icon,
  message = "",
  disabled = false,
  to = "",
  active = false,
}: props) => {
  return (
    <li>
      <Link
        to={to}
        className={
          active
            ? "bg-green-400 flex items-center p-2 text-gray-900 rounded-lg group"
            : "flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
        }
        onClick={disabled ? (e) => e.preventDefault() : (e) => {}}
      >
        {icon}
        <span className="flex-1 ml-3 whitespace-nowrap">{name}</span>
        {message && (
          <span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full">
            {message}
          </span>
        )}
      </Link>
    </li>
  )
}

export default SidebarItem
