import axios from "axios"
import ENDPOINT from "../utils/Endpoint"

const getTradeResult = async (id: string, shareAddress: string) => {
  try {
    let res = await axios.get(
      ENDPOINT + "traderesult/" + id + "/" + shareAddress
    )

    console.log(res.data)

    return res.data
  } catch (error) {
    throw new Error("Error fetching data from the API")
  }
}

export default getTradeResult
