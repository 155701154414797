import React from "react"
import { useState, useEffect } from "react"

import turtle1 from "../../utils/images/turtle1.png"
import turtle2 from "../../utils/images/turtle2.png"
import turtle3 from "../../utils/images/turtle3.png"
import turtle4 from "../../utils/images/turtle4.png"
import turtle5 from "../../utils/images/turtle5.png"
import turtle6 from "../../utils/images/turtle6.png"
import turtle7 from "../../utils/images/turtle7.png"
import turtle8 from "../../utils/images/turtle8.png"

const PhotoSlider = () => {
  const turtleArr = [
    turtle1,
    turtle2,
    turtle3,
    turtle4,
    turtle5,
    turtle6,
    turtle7,
    turtle8,
  ]
  const [turtleState, setTurtleState] = useState(
    Math.floor(Math.random() * turtleArr.length)
  )

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTurtleState((turtleState + 1) % turtleArr.length)
  //   }, 3000)
  // }, [turtleState]);

  return (
    <div>
      <div className="border-4 border-green-700">
        <img src={turtleArr[2]} />
      </div>
    </div>
  )
}

export default PhotoSlider
