import logoname from "../../utils/images/logoname.png"
import SidebarItem from "../SidebarItem"
import { StatsIcon, UserIcon } from "../../utils/icons"
import CloseIcon from "../../utils/images/icons-close.svg"

type SidebarProps = {
  active: number
  hamburger?: boolean
  handleCloseClick?: any
}

const Sidebar = ({
  active,
  hamburger = false,
  handleCloseClick,
}: SidebarProps) => {
  return (
    <div className="flex flex-col w-80 h-screen fixed bg-gray-50 shadow-lg px-5 py-5 space-y-4">
      {!hamburger ? (
        <div className="row-span-2 border-b-2 border-gray-300">
          <img src={logoname} />
        </div>
      ) : (
        <div className="ml-auto mr-1" onClick={() => handleCloseClick()}>
          <img src={CloseIcon} alt="Close Icon" />
        </div>
      )}

      <div className="grow shrink basis-0 h-96 px-3 py-4 overflow-y-auto bg-gray-50">
        <ul className="space-y-2 font-medium">
          <SidebarItem
            name="Home Page"
            icon={UserIcon}
            to="/"
            active={active === 1}
          />
          <SidebarItem
            name="Trader Profile"
            icon={UserIcon}
            to="/trader"
            active={active === 2}
          />
          <SidebarItem
            name="Share"
            icon={UserIcon}
            to="/share"
            active={active === 3}
          />
          <SidebarItem
            name="Top Shares"
            icon={StatsIcon}
            to="/topshares"
            active={active === 4}
          />
          <SidebarItem
            name="Top Traders"
            icon={StatsIcon}
            to="/toptraders"
            active={active === 5}
          />
          <SidebarItem
            name="Most Searched"
            icon={StatsIcon}
            message="Soon!"
            to="/"
            active={active === 6}
            disabled
          />
          <SidebarItem
            name="Alpha"
            icon={StatsIcon}
            message="Soon!"
            to="/"
            active={active === 7}
            disabled
          />
        </ul>
      </div>

      <div className="mb-8 w-full aspect-[4/3] bg-green-700 rounded-lg p-4 text-center">
        <div className="font-mono font-bold text-white text-base">
          Want to hear more about Turtleshack ?
        </div>
        <div className="mt-7 font-mono text-white text-xs">
          Subscribe and get updated about new features and releases!
        </div>
        <div className="flex mt-10">
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="user@turtleshack.xyz"
            required
          />
          <button
            type="submit"
            className="text-green-700 hover:text-green-300 bg-white hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm w-full sm:w-auto px-2 py-2 text-center ml-1"
          >
            Subscribe
          </button>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
