import { useEffect, useState, useMemo } from "react"
import { ethers } from "ethers"
import { max, extent } from "@visx/vendor/d3-array"
import { scaleLinear } from "@visx/scale"
import { LinearGradient } from "@visx/gradient"
import { AxisLeft, AxisBottom, AxisScale } from "@visx/axis"
import { LinePath } from "@visx/shape"
import { Group } from "@visx/group"
import { GridRows, GridColumns } from "@visx/grid"
import { curveBasis } from "@visx/curve"

type ProtocolChartProps = {
  compact?: boolean
  width: number
  height: number
  historyBlocks: number[]
  historyEther: string[]
  margin?: { top: number; right: number; bottom: number; left: number }
}

type ProtocolData = {
  block: number
  ether: number
}

const getBlock = (d: ProtocolData) => d.block
const getEther = (d: ProtocolData) => d.ether

const chartSeparation = 30
const GRADIENT_ID = "brush_gradient"
const axisColor = "green"
const axisBottomTickLabelProps = {
  textAnchor: "middle" as const,
  fontFamily: "Arial",
  fontSize: 10,
  fill: axisColor,
}
const axisLeftTickLabelProps = {
  dx: "-0.25em",
  dy: "0.25em",
  fontFamily: "Arial",
  fontSize: 10,
  textAnchor: "end" as const,
  fill: axisColor,
}

const ProtocolChart = ({
  compact = false,
  width,
  height,
  historyBlocks,
  historyEther,
  margin = {
    top: 20,
    left: 50,
    bottom: 40,
    right: 20,
  },
}: ProtocolChartProps) => {
  const [data, setData] = useState<ProtocolData[]>([])

  const innerHeight = height - margin.top
  const topChartHeight = innerHeight - margin.bottom

  const xMax = Math.max(width - margin.left - margin.right, 0)
  const yMax = Math.max(topChartHeight, 0)

  // scales
  const dateScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xMax],
        domain: extent(data, getBlock) as [number, number],
      }),
    [xMax, data]
  )
  const stockScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        domain: extent(data, getEther) as [number, number],
      }),
    [yMax, data]
  )

  useEffect(() => {
    let dataToSet = historyBlocks.map((h, i) => {
      return {
        block: h,
        ether: parseFloat(ethers.utils.formatUnits(historyEther[i], 9)),
      }
    })

    setData([...dataToSet])

    console.log(dataToSet)
  }, [historyBlocks])

  useEffect(() => {
    console.log("DATA:  ", data)
  }, [data])

  return (
    <div className="border-l">
      <svg width={width} height={height}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          stroke="url(#gradient)"
          fill="white"
          rx={14}
        />
        <Group left={margin.left} top={margin.top}>
          <AxisLeft
            scale={stockScale}
            numTicks={5}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={axisLeftTickLabelProps}
            hideAxisLine
            hideZero
            hideTicks
          />
          <LinePath
            stroke={"darkgray"}
            strokeWidth={2}
            data={data}
            x={(d: ProtocolData) => dateScale(getBlock(d)) ?? 0}
            y={(d: ProtocolData) => stockScale(getEther(d)) ?? 0}
            curve={curveBasis}
          />
          <AxisBottom
            top={yMax}
            scale={dateScale}
            numTicks={width > 520 ? 5 : 3}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={axisBottomTickLabelProps}
            hideAxisLine
            hideZero
            hideTicks
          />
        </Group>
      </svg>
    </div>
  )
}

export default ProtocolChart
