import { useState, FC } from "react"
import { Link } from "react-router-dom"
import { ethers } from "ethers"

type TableProps<T> = {
  header: string
  data: T[]
  columns: [string, string, string, string]
  columnHeaders: [string, string, string, string]
  columnViewers: [any, any, any, any]
  itemName: string
  handleVisit: any
  viewMore?: boolean
  page?: boolean
  startNumber?: number
  to?: string
  isProfit?: boolean
}

const Table: FC<TableProps<any>> = ({
  header,
  data,
  columns,
  columnHeaders,
  columnViewers,
  itemName,
  handleVisit,
  viewMore = false,
  page = false,
  startNumber = 0,
  to = "",
  isProfit = false
}) => {
  return (
    <div>
      <section className="mt-10 font-mono">
        <h3 className="text-2xl font-bold mb-4">{header}</h3>

        <div
          className={`mt-5 relative overflow-x-auto shadow-md sm:rounded-lg ${!page && "max-h-128 overflow-y-scroll"
            } ${viewMore && "shadow-none"}`}
        >
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
              <tr>
                {page && (
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                )}
                <th scope="col" className="px-6 py-3">
                  {columnHeaders[0]}
                </th>
                <th scope="col" className="px-6 py-3">
                  {columnHeaders[1]}
                </th>
                <th scope="col" className="px-6 py-3 hidden tablet:table-cell">
                  {columnHeaders[2]}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 hidden bigmobile:table-cell"
                >
                  {columnHeaders[3]}
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((t, i) => {
                return (
                  <tr className="bg-white border-b" key={t[columns[0]]}>
                    {page && (
                      <td className="px-6 py-4">{startNumber + i + 1}</td>
                    )}
                    <th
                      scope="row"
                      className="px-2 bigmobile:px-6 py-4 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >

                      {
                        t[columns[0]].length > 20 ?
                          <>
                            <p className="block bigmobile:hidden">
                              {columnViewers[0](t[columns[0]]).slice(0, 7) + "..." + columnViewers[0](t[columns[0]]).slice(-3)}
                            </p>
                            <p className="hidden bigmobile:block tablet:hidden">
                              {columnViewers[0](t[columns[0]])}
                            </p>
                            <p className="hidden tablet:block laptop:hidden">
                              {columnViewers[0](t[columns[0]]).slice(0, 12) + "..." + columnViewers[0](t[columns[0]]).slice(-12)}
                            </p>
                            <p className="hidden laptop:block">
                              {columnViewers[0](t[columns[0]])}
                            </p>
                          </>
                          :
                          <p className="block">{columnViewers[0](t[columns[0]])} </p>
                      }
                    </th>
                    <td className="px-6 py-4">
                      {columnViewers[1](t[columns[1]])}
                    </td>
                    <td className="px-6 py-4 hidden tablet:table-cell">
                      {columnViewers[2](t[columns[2]])}
                    </td>
                    <td className="px-6 py-4 hidden bigmobile:table-cell">
                      {
                        isProfit ?
                          ethers.utils
                            .formatUnits(
                              BigInt(t[columns[2]]) - BigInt(t[columns[1]]),
                              9
                            )
                            .slice(0, 5)
                          :
                          columnViewers[3](t[columns[3]])
                      }

                    </td>
                    <td className="px-6 py-4">
                      <div
                        onClick={(e) => handleVisit(e, t[columns[0]], itemName, i, t)}
                        className="font-medium text-blue-600 hover:underline cursor-pointer"
                      >
                        Visit
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {viewMore && (
          <Link to={to}>
            <div className="w-full text-center p-2 bg-gray-100 hover:bg-gray-200 font-mono font-bold cursor-pointer shadow-md rounded-b-2xl">
              View More
            </div>
          </Link>
        )}
      </section>
    </div>
  )
}

export default Table
