import axios from "axios"
import ENDPOINT from "../utils/Endpoint"

const getShare = async (address: string) => {
  try {
    let res = await axios.get(ENDPOINT + "share/" + address)

    console.log(res.data)

    return res.data
  } catch (error) {
    throw new Error("Error fetching data from the API")
  }
}

export default getShare
