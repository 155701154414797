type I_InfoBox = {
  name: string
  value: string
}

const InfoBox = ({ name, value }: I_InfoBox) => {
  return (
    <div className="col-span-2 p-4 border-2 text-center border-blue-700 bg-neutral-50">
      <div className="font-bold font-mono text-sm laptop:text-xl text-green-700">
        {name}
      </div>
      <div className="font-bold font-mono text-lg laptop:text-2xl mt-2">
        {value}
      </div>
    </div>
  )
}

export default InfoBox