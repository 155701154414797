import React from "react"
import PhotoSlider from "../PhotoSlider"
import ContactUs from "../ContactUs"

const VerticalInfo = () => {
  return (
    <div className="flex justify-between h-100 my-10 p-5 shadow-lg bg-white border desktop:hidden">
      <div className="w-64 mx-auto bigmobile:mx-0">
        <PhotoSlider />
      </div>
      <div className="hidden bigmobile:block">
        <ContactUs />
      </div>
    </div>
  )
}

export default VerticalInfo
