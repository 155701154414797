import axios from "axios"
import ENDPOINT from "../utils/Endpoint"

async function getTopTraders() {
  try {
    let res = await axios.get(ENDPOINT + "toptraders/")

    return res.data
  } catch (error) {
    throw new Error("Error fetching data from the API")
  }
}

export default getTopTraders
