import React, { useRef, useState, useMemo, useEffect, useCallback } from "react"
import { scaleLinear } from "@visx/scale"
import { AppleStock } from "@visx/mock-data/lib/mocks/appleStock"
import { Brush } from "@visx/brush"
import { Bounds } from "@visx/brush/lib/types"
import BaseBrush from "@visx/brush/lib/BaseBrush"
import { PatternLines } from "@visx/pattern"
import { Group } from "@visx/group"
import { LinearGradient } from "@visx/gradient"
import { max, extent } from "@visx/vendor/d3-array"
import { BrushHandleRenderProps } from "@visx/brush/lib/BrushHandle"
import AreaChart from "../../AreaChart"
import { ethers } from "ethers"
import { withTooltip } from "@visx/tooltip"
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip"
import { localPoint } from "@visx/event"
import { PointsRange } from "@visx/mock-data/lib/generators/genRandomNormalPoints"
import { supplyToEthPrice } from "../../../../utils/helper"

// Initialize some variables
//const stock = appleStock.slice(1000);
const brushMargin = { top: 10, bottom: 15, left: 50, right: 20 }
const chartSeparation = 30
const PATTERN_ID = "brush_pattern"
const GRADIENT_ID = "brush_gradient"
export const accentColor = "#f6acc8"
export const background = "white"
export const background2 = "green"
const selectedBrushStyle = {
  fill: `url(#${PATTERN_ID})`,
  stroke: "white",
}

// accessors
const getDate = (d: AppleStock) => parseFloat(d.date)
const getStockValue = (d: AppleStock) => d.close

export type BrushProps = {
  width: number
  height: number
  historyBlocks: number[]
  historyProfit: string[]
  margin?: { top: number; right: number; bottom: number; left: number }
  compact?: boolean
  traderHistory?: number[]
  trades?: Trade[]
}

let tooltipTimeout: number

export default withTooltip<BrushProps, PointsRange>(
  ({
    compact = false,
    width,
    height,
    historyBlocks,
    historyProfit,
    margin = {
      top: 20,
      left: 30,
      bottom: 20,
      right: 20,
    },
    trades = [],
    hideTooltip,
    showTooltip,
  }: BrushProps & WithTooltipProvidedProps<PointsRange>) => {
    const brushRef = useRef<BaseBrush | null>(null)
    const [stock, setStock] = useState<AppleStock[]>([])
    const [filteredStock, setFilteredStock] = useState(stock)
    const svgRef = useRef<SVGSVGElement>(null)
    const innerHeight = height - margin.top - margin.bottom
    const topChartBottomMargin = compact
      ? chartSeparation / 2
      : chartSeparation + 10
    const topChartHeight = 0.8 * innerHeight - topChartBottomMargin
    const bottomChartHeight = innerHeight - topChartHeight - chartSeparation

    // bounds
    const xMax = Math.max(width - margin.left - margin.right, 0)
    const yMax = Math.max(topChartHeight, 0)
    const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0)
    const yBrushMax = Math.max(
      bottomChartHeight - brushMargin.top - brushMargin.bottom,
      0
    )

    useEffect(() => {
      if (!trades || !trades.length) return

      const currentValuation = {
        shareAmount: BigInt(0),
        etherAmount: BigInt(0),
      }

      let tradeMap = new Map()

      trades.forEach((trade) => {
        if (!tradeMap.get(trade.block_number)) {
          tradeMap.set(trade.block_number, [trade])
        } else {
          tradeMap.set(trade.block_number, [
            ...tradeMap.get(trade.block_number),
            trade,
          ])
        }
      })

      let stockArr: AppleStock[] = []

      historyBlocks.forEach((block, index) => {
        const tradesAtBlock: Trade[] = tradeMap.get(block)
        const shareValuation = BigInt(historyProfit[index])
        if (tradesAtBlock) {
          tradesAtBlock.forEach((trade) => {
            if (trade.is_buy) {
              currentValuation.shareAmount += BigInt(trade.share_amount)
              currentValuation.etherAmount -=
                BigInt(trade.share_amount) * shareValuation
            } else {
              currentValuation.shareAmount -= BigInt(trade.share_amount)
              currentValuation.etherAmount +=
                BigInt(trade.share_amount) * shareValuation
            }
          })
        }

        const valuation =
          currentValuation.etherAmount +
          currentValuation.shareAmount * shareValuation
        stockArr.push({
          date: block.toString(),
          close: parseFloat(ethers.utils.formatUnits(valuation, 9)),
        })
      })

      setStock([...stockArr])
      setFilteredStock([...stockArr])
    }, [historyProfit, historyBlocks])

    const onBrushChange = (domain: Bounds | null) => {
      if (!domain) return
      const { x0, x1, y0, y1 } = domain
      const stockCopy = stock.filter((s) => {
        const x = getDate(s)
        const y = getStockValue(s)
        return x > x0 && x < x1 && y > y0 && y < y1
      })
      setFilteredStock(stockCopy)
    }

    useEffect(() => {
      console.log("STOCK CHANGEd: ", stock)
    }, [stock])

    // scales
    const dateScale = useMemo(
      () =>
        scaleLinear<number>({
          range: [0, xMax],
          domain: extent(filteredStock, getDate) as [number, number],
        }),
      [xMax, filteredStock]
    )
    const stockScale = useMemo(
      () =>
        scaleLinear<number>({
          range: [yMax, 0],
          domain: extent(filteredStock, getStockValue) as [number, number],
        }),
      [yMax, filteredStock]
    )
    const brushDateScale = useMemo(
      () =>
        scaleLinear<number>({
          range: [0, xBrushMax],
          domain: extent(stock, getDate) as [number, number],
        }),
      [xBrushMax, stock]
    )
    const brushStockScale = useMemo(
      () =>
        scaleLinear({
          range: [yBrushMax, 0],
          domain: extent(stock, getStockValue) as [number, number],
          nice: true,
        }),
      [yBrushMax, stock]
    )

    // event handlers
    const handleMouseMove = useCallback(
      (event: React.MouseEvent | React.TouchEvent) => {
        if (tooltipTimeout) clearTimeout(tooltipTimeout)
        if (!svgRef.current) return

        // find the nearest polygon to the current mouse position
        const point = localPoint(svgRef.current, event)

        console.log(point)
        if (!point) return
        const neighborRadius = 15
        const closest = trades.find((t) => {
          console.log(dateScale(t.block_number))
          return (
            point.x <=
              dateScale(t.block_number) + neighborRadius + margin.left &&
            point.x >=
              dateScale(t.block_number) - neighborRadius + margin.right &&
            point.y <=
              stockScale(supplyToEthPrice(t.supply)) +
                neighborRadius +
                margin.bottom &&
            point.y >=
              stockScale(supplyToEthPrice(t.supply)) -
                neighborRadius +
                margin.top
          )
        })
        console.log("CLOSEST: ", closest)
        if (closest) {
          showTooltip({
            tooltipLeft: dateScale(closest.block_number) + margin.left,
            tooltipTop: stockScale(supplyToEthPrice(closest.supply)),
            tooltipData: [
              closest.share_amount,
              supplyToEthPrice(closest.supply),
              closest.is_buy ? 1 : 0,
            ],
          })
        } else {
          hideTooltip()
        }
      },
      [dateScale, stockScale, showTooltip, trades]
    )

    const handleMouseLeave = useCallback(() => {
      tooltipTimeout = window.setTimeout(() => {
        hideTooltip()
      }, 300)
    }, [hideTooltip])

    return (
      <div
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseLeave}
      >
        {
          <svg width={width} height={height} ref={svgRef}>
            <LinearGradient
              id={GRADIENT_ID}
              from={background}
              to={background}
              rotate={45}
            />
            <rect
              x={0}
              y={0}
              width={width}
              height={height}
              fill={`url(#${GRADIENT_ID})`}
              rx={10}
            />
            <AreaChart
              hideBottomAxis={compact}
              data={filteredStock}
              width={width}
              margin={{ ...margin, bottom: topChartBottomMargin }}
              yMax={yMax}
              xScale={dateScale}
              yScale={stockScale}
              gradientColor={background2}
            ></AreaChart>

            <svg className="hidden laptop:block">
              <AreaChart
                hideBottomAxis
                hideLeftAxis
                data={stock}
                width={width}
                yMax={yBrushMax}
                xScale={brushDateScale}
                yScale={brushStockScale}
                margin={brushMargin}
                top={topChartHeight + topChartBottomMargin + margin.top}
                gradientColor={background2}
              >
                <PatternLines
                  id={PATTERN_ID}
                  height={8}
                  width={8}
                  stroke={accentColor}
                  strokeWidth={1}
                  orientation={["diagonal"]}
                />

                <Brush
                  xScale={brushDateScale}
                  yScale={brushStockScale}
                  width={xBrushMax}
                  height={yBrushMax}
                  margin={brushMargin}
                  handleSize={18}
                  innerRef={brushRef}
                  resizeTriggerAreas={["left", "right"]}
                  brushDirection="horizontal"
                  initialBrushPosition={{
                    start: { x: 100 },
                    end: { x: 250 },
                  }}
                  onChange={onBrushChange}
                  onClick={() => setFilteredStock(stock)}
                  selectedBoxStyle={selectedBrushStyle}
                  useWindowMoveEvents
                  renderBrushHandle={(props) => <BrushHandle {...props} />}
                />
              </AreaChart>
            </svg>
          </svg>
        }
      </div>
    )
  }
)
// We need to manually offset the handles for them to be rendered at the right position
function BrushHandle({ x, height, isBrushActive }: BrushHandleRenderProps) {
  const pathWidth = 8
  const pathHeight = 15
  if (!isBrushActive) {
    return null
  }
  return (
    <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
      <path
        fill="#f2f2f2"
        d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
        stroke="#999999"
        strokeWidth="1"
        style={{ cursor: "ew-resize" }}
      />
    </Group>
  )
}
