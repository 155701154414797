import React from "react"
import { useNavigate } from "react-router-dom"
import BackIcon from "../../utils/images/whitebackicon.png"


const GoBackButton = () => {
  const navigate = useNavigate()

  return (
    <div className="w-fit">
      <button
        onClick={() => navigate(-1)}
        className="flex flex-row w-fit items-center justify-center gap-6 px-6 py-2 bg-green-700 rounded-2xl hover:bg-green-800 active:bg-green-900"
      >
        <img className="w-3 h-auto" src={BackIcon} alt="back-icon" />
        <p className="font-semibold text-md text-white">Back</p>
      </button>
    </div>
  )
}

export default GoBackButton