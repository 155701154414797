import React, { useRef, useState, useMemo, useEffect } from "react"
import { scaleLinear } from "@visx/scale"
import { AppleStock } from "@visx/mock-data/lib/mocks/appleStock"
import { Brush } from "@visx/brush"
import { Bounds } from "@visx/brush/lib/types"
import BaseBrush, {
  BaseBrushState,
  UpdateBrush,
} from "@visx/brush/lib/BaseBrush"
import { PatternLines } from "@visx/pattern"
import { Group } from "@visx/group"
import { LinearGradient } from "@visx/gradient"
import { max, extent } from "@visx/vendor/d3-array"
import { BrushHandleRenderProps } from "@visx/brush/lib/BrushHandle"
import AreaChart from "./AreaChart"
import { ethers } from "ethers"
import { Circle } from "@visx/shape"
import { supplyToEthPrice } from "../../utils/helper"

// Initialize some variables
//const stock = appleStock.slice(1000);
const brushMargin = { top: 10, bottom: 15, left: 50, right: 20 }
const chartSeparation = 30
const PATTERN_ID = "brush_pattern"
const GRADIENT_ID = "brush_gradient"
export const accentColor = "#f6acc8"
export const background = "white"
export const background2 = "green"
const selectedBrushStyle = {
  fill: `url(#${PATTERN_ID})`,
  stroke: "white",
}

// accessors
const getDate = (d: AppleStock) => parseFloat(d.date)
const getStockValue = (d: AppleStock) => d.close

export type BrushProps = {
  width: number
  height: number
  historyBlocks: number[]
  historyProfit: string[]
  margin?: { top: number; right: number; bottom: number; left: number }
  compact?: boolean
  traderHistory?: number[]
  trades?: Trade[]
}

function BrushChart({
  compact = false,
  width,
  height,
  historyBlocks,
  historyProfit,
  margin = {
    top: 20,
    left: 50,
    bottom: 20,
    right: 20,
  },
  traderHistory = [],
  trades = [],
}: BrushProps) {
  const brushRef = useRef<BaseBrush | null>(null)
  const [stock, setStock] = useState<AppleStock[]>([])
  const [filteredStock, setFilteredStock] = useState(stock)
  const [dateScalee, setDateScale] = useState<any>(
    scaleLinear<number>({
      range: [0, 0],
      domain: [0, 0],
    })
  )
  const [stockScalee, setStockScale] = useState<any>(
    scaleLinear<number>({
      range: [0, 0],
      domain: [0, 0],
    })
  )
  const [profitEther, setProfitEther] = useState<number[]>([])

  const innerHeight = height - margin.top - margin.bottom
  const topChartBottomMargin = compact
    ? chartSeparation / 2
    : chartSeparation + 10
  const topChartHeight = 0.8 * innerHeight - topChartBottomMargin
  const bottomChartHeight = innerHeight - topChartHeight - chartSeparation

  // bounds
  const xMax = Math.max(width - margin.left - margin.right, 0)
  const yMax = Math.max(topChartHeight, 0)
  const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0)
  const yBrushMax = Math.max(
    bottomChartHeight - brushMargin.top - brushMargin.bottom,
    0
  )

  useEffect(() => {
    let historyEther = historyProfit.map((e) =>
      parseFloat(ethers.utils.formatUnits(e, 9))
    )
    setProfitEther([...historyEther])

    let stockArr: AppleStock[] = []

    for (let i = 0; i < historyProfit.length; i++) {
      let s: AppleStock = {
        date: historyBlocks[i].toString(),
        close: historyEther[i],
      }

      stockArr.push(s)
    }

    setStock([...stockArr])
    setFilteredStock([...stockArr])
  }, [historyProfit])

  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) return
    const { x0, x1, y0, y1 } = domain
    const stockCopy = stock.filter((s) => {
      const x = getDate(s)
      const y = getStockValue(s)
      return x > x0 && x < x1 && y > y0 && y < y1
    })
    setFilteredStock(stockCopy)
  }

  const getXDomain = (filteredStock: AppleStock[]) => {
    let minN = -5
    let maxN = 5

    if ((extent(filteredStock, getStockValue)[0] as number) < -5) {
      minN = extent(filteredStock, getStockValue)[0] as number
    }

    if ((extent(filteredStock, getStockValue)[1] as number) > 5) {
      maxN = extent(filteredStock, getStockValue)[1] as number
    }

    return [minN, maxN]
  }

  useEffect(() => {
    console.log("STOCK CHANGEd: ", stock)
  }, [stock])

  // scales
  const dateScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xMax],
        domain: extent(filteredStock, getDate) as [number, number],
      }),
    [xMax, filteredStock]
  )
  const stockScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        domain: extent(filteredStock, getStockValue) as [number, number],
      }),
    [yMax, filteredStock]
  )
  const brushDateScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xBrushMax],
        domain: extent(stock, getDate) as [number, number],
      }),
    [xBrushMax, stock]
  )
  const brushStockScale = useMemo(
    () =>
      scaleLinear({
        range: [yBrushMax, 0],
        domain: extent(stock, getStockValue) as [number, number],
        nice: true,
      }),
    [yBrushMax, stock]
  )

  const initialBrushPosition = useMemo(
    () => ({
      start: { x: brushDateScale(parseFloat("3802010")) },
      end: { x: brushDateScale(parseFloat("3902010")) },
    }),
    [brushDateScale, stock]
  )

  return (
    <div>
      {
        <svg width={width} height={height}>
          <LinearGradient
            id={GRADIENT_ID}
            from={background}
            to={background}
            rotate={45}
          />
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill={`url(#${GRADIENT_ID})`}
            rx={14}
          />
          <AreaChart
            hideBottomAxis={compact}
            data={filteredStock}
            width={width}
            margin={{ ...margin, bottom: topChartBottomMargin }}
            yMax={yMax}
            xScale={dateScale}
            yScale={stockScale}
            gradientColor={background2}
          >
            {trades.map((trade, i) => {
              return (
                <Circle
                  key={`point-${trade.hash}-${i}`}
                  className="dot"
                  cx={dateScale(trade.block_number)}
                  cy={stockScale(supplyToEthPrice(trade.supply))}
                  r={7}
                  fill={trade.is_buy ? "#F568E9" : "#8B2AFA"}
                />
              )
            })}
          </AreaChart>

          <svg className="hidden laptop:block">
            <AreaChart
              hideBottomAxis
              hideLeftAxis
              data={stock}
              width={width}
              yMax={yBrushMax}
              xScale={brushDateScale}
              yScale={brushStockScale}
              margin={brushMargin}
              top={topChartHeight + topChartBottomMargin + margin.top}
              gradientColor={background2}
            >
              <PatternLines
                id={PATTERN_ID}
                height={8}
                width={8}
                stroke={accentColor}
                strokeWidth={1}
                orientation={["diagonal"]}
              />

              <Brush
                xScale={brushDateScale}
                yScale={brushStockScale}
                width={xBrushMax}
                height={yBrushMax}
                margin={brushMargin}
                handleSize={18}
                innerRef={brushRef}
                resizeTriggerAreas={["left", "right"]}
                brushDirection="horizontal"
                initialBrushPosition={{
                  start: { x: 100 },
                  end: { x: 250 },
                }}
                onChange={onBrushChange}
                onClick={() => setFilteredStock(stock)}
                selectedBoxStyle={selectedBrushStyle}
                useWindowMoveEvents
                renderBrushHandle={(props) => <BrushHandle {...props} />}
              />
            </AreaChart>
          </svg>
        </svg>
      }
    </div>
  )
}
// We need to manually offset the handles for them to be rendered at the right position
function BrushHandle({ x, height, isBrushActive }: BrushHandleRenderProps) {
  const pathWidth = 8
  const pathHeight = 15
  if (!isBrushActive) {
    return null
  }
  return (
    <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
      <path
        fill="#f2f2f2"
        d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
        stroke="#999999"
        strokeWidth="1"
        style={{ cursor: "ew-resize" }}
      />
    </Group>
  )
}

export default BrushChart
