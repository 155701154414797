import React from "react"
import { useState, useEffect, FC } from "react"
import Table from "../Table"
import { useNavigate } from "react-router-dom"
import { ethers } from "ethers"
import GoBackButton from "../GoBackButton"

import getMostValShares from "../../requests/getMostValShares"
import { viewSelf, viewSupplyPrice, viewEther } from "../../utils/helper"
import WaitingComponent from "../WaitingComponent"

type TablePageProps<T> = {
  name: string
  dataCall: any
  columns: [string, string, string, string]
  columnHeaders: [string, string, string, string]
  columnViewers: [any, any, any, any]
  itemName: string
}

const TablePage: FC<TablePageProps<any>> = ({
  name,
  dataCall,
  columns,
  columnHeaders,
  columnViewers,
  itemName
}) => {
  const [mostValShares, setValShares] = useState<any[]>([])
  const [page, setPage] = useState(0)
  const dataCount = 20

  const navigate = useNavigate()

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1)
    }
  }

  const handleNextPage = () => {
    setPage(page + 1)
  }

  const handleVisit = (e: any, itemAddress: string, itemUrl: string) => {
    e.preventDefault()

    navigate(`/${itemUrl}/${itemAddress}`)
  }

  const handleGetData = async () => {
    try {
      let res = await dataCall(dataCount, page)

      setValShares(res.result)
      window.scrollTo(0, 0)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    handleGetData()
  }, [page])

  return (
    <div className="block p-00 bigmobile:p-4 mdscreen:p-8 mx-auto laptop:w-[68rem] tablet:ml-80 desktop:mr-80">
      <GoBackButton />
      {
        mostValShares.length > 0 ?
          <>
            <Table
              header={name}
              data={mostValShares}
              columns={columns}
              columnHeaders={columnHeaders}
              columnViewers={columnViewers}
              itemName={itemName}
              handleVisit={handleVisit}
              page
              startNumber={dataCount * page}
            />


            {/* Page Counter */}
            <div className="flex flex-col items-center mt-5">
              <span className="text-sm text-gray-700 dark:text-gray-400">
                Showing page{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {page + 1}
                </span>{" "}
                with{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  20
                </span>{" "}
                entries
              </span>
              <div className="inline-flex mt-2 xs:mt-0">
                <button
                  onClick={() => handlePrevPage()}
                  className="flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <svg
                    className="w-3.5 h-3.5 mr-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 5H1m0 0 4 4M1 5l4-4"
                    />
                  </svg>
                  Prev
                </button>
                <button
                  onClick={() => handleNextPage()}
                  className="flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-gray-800 border-0 border-l border-gray-700 rounded-r hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  Next
                  <svg
                    className="w-3.5 h-3.5 ml-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </>
          :
          <WaitingComponent />
      }

    </div>
  )
}

export default TablePage
