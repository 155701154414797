import React from "react"
import Sidebar from "../../components/Sidebar"
import RightSideBar from "../../components/RightSideBar"

type LayoutProps = {
  active: number
  Component: JSX.Element
}

const Layout = ({ active, Component }: LayoutProps) => {
  return (
    <div className="flex justify-between relative">
      <div>
        <div className="hidden tablet:block">
          <Sidebar active={active} />
        </div>
      </div>
      <div className="w-full h-screen p-1">{Component}</div>
      <div>
        <RightSideBar />
      </div>
    </div>
  )
}

export default Layout
