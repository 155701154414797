import { useEffect, useState } from "react"
import SearchBoxHeader from "../SearchBoxHeader"
import BrushChart from "../Charts/BrushChart"
import Table from "../Table"
import LargeInfoBox from "../LargeInfoBox"
import InfoBox from "../InfoBox"
import { viewSelf, viewEther, viewSupplyPrice, viewSmallEther } from "../../utils/helper"
import getTrader from "../../requests/getTrader"
import { Link } from "react-router-dom"
import { ethers } from "ethers"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { useParams, useNavigate } from "react-router-dom"
import HorizontalScrollNavigation from "../HorizontalScrollNavigation"
import WaitingComponent from "../WaitingComponent"

const TraderProfile = () => {
  let emptyTrader: Trader = {
    address: "",
    history_blocks: [],
    history_profit: [],
    total_eth_profit: "0",
    trade_result_count: 0,
    success_count: 0,
  }
  const [trader, setTrader] = useState<Trader>(emptyTrader)

  const [tradeResults, setTradeResults] = useState<any[]>([])
  const [graphWidth, setGRaphWidth] = useState(1000)
  const [graphHeight, setGRaphHeight] = useState(500)

  const { width, height } = useWindowDimensions()

  const navigate = useNavigate()

  const { traderAddress } = useParams()

  const handleSearch = async (traderAddress: string) => {
    let lowerAddress = traderAddress.toLowerCase()

    if (!ethers.utils.isAddress(lowerAddress)) {
      console.log("not address")
      return
    }

    navigate(`/trader/${lowerAddress}`)
  }

  const handleGetTrader = async (traderAddress: string) => {
    try {
      let res = await getTrader(traderAddress)
      console.log("res: ", res)
      setTrader(res.result)
      setTradeResults(res.trade_results || emptyTrader)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetTrader(traderAddress || "")
  }, [traderAddress])

  useEffect(() => {
    if (width > 1415) {
      setGRaphWidth(1000)
      setGRaphHeight(500)
    } else if (width > 1200) {
      setGRaphWidth(800)
      setGRaphHeight(400)
    } else if (width > 750) {
      setGRaphWidth(600)
      setGRaphHeight(400)
    } else if (width > 470) {
      setGRaphWidth(400)
      setGRaphHeight(300)
    } else {
      setGRaphWidth(330)
      setGRaphHeight(300)
    }
  }, [width])

  const graphs = [
    <BrushChart
      width={graphWidth}
      height={graphHeight}
      historyBlocks={trader.history_blocks}
      historyProfit={trader.history_profit}
    />,
  ]
  const graphNames = ["Trader Net Ether"]

  const handleVisit = (e: any, itemAddress: string, itemUrl: string, i: any, tradeRes: any) => {
    e.preventDefault()

    navigate(`/${itemUrl}/${tradeRes.id}/${itemAddress}`)
  }

  return (
    <div className="block p-00 bigmobile:p-4 mdscreen:p-8 mx-auto laptop:w-[68rem] mdscreen:ml-80 desktop:mr-80">
      <SearchBoxHeader
        handleSearch={handleSearch}
        header="Trader"
        explanation={trader.address}
        expMobile={
          trader.address.slice(0, 12) + "..." + trader.address.slice(-7)
        }
      />
      {trader.address ? (
        <main className="p-4">
          <HorizontalScrollNavigation
            components={graphs}
            headers={graphNames}
          />
          <div className="bigmobile:grid grid-cols-7 gap-4 w-9/12 mx-auto">
            <LargeInfoBox
              name="Total ETH Profit"
              value={viewEther(trader.total_eth_profit)}
            />
            <InfoBox
              name="Total Trades"
              value={trader.trade_result_count.toString()}
            />
            <InfoBox
              name="Success Count"
              value={trader.success_count.toString()}
            />
          </div>

          <Table
            header="Trade Results"
            data={tradeResults}
            columns={[
              "subject",
              "ether_in",
              "ether_out",
              "profit",
            ]}
            columnHeaders={["Trader", "Ether In", "Ether Out", "Profit"]}
            columnViewers={[viewSelf, viewSmallEther, viewSmallEther, viewSelf]}
            itemName="traderesult"
            handleVisit={handleVisit}
            isProfit
          />
        </main>
      ) : (
        <WaitingComponent
          message="enter a trader address"
        />
      )}
    </div>
  )
}

export default TraderProfile
