import React from "react"
import { useState, useEffect } from "react"
import SearchBoxHeader from "../SearchBoxHeader"
import { ethers } from "ethers"
import { useNavigate } from "react-router-dom"
import getTopTraders from "../../requests/getTopTraders"
import Table from "../Table"
import { viewEther, viewSelf } from "../../utils/helper"
import WaitingComponent from "../WaitingComponent"

const TopTraders = () => {
  const [mostProfitedTraders, setProfitedTraders] = useState<TraderItem[]>([])

  const navigate = useNavigate()

  const handleSearch = async (traderAddress: string) => {
    let lowerAddress = traderAddress.toLowerCase()

    if (!ethers.utils.isAddress(lowerAddress)) {
      console.log("not address")
      return
    }

    navigate(`/trader/${lowerAddress}`)
  }

  const handleVisit = (e: any, itemAddress: string, itemUrl: string) => {
    e.preventDefault()

    navigate(`/${itemUrl}/${itemAddress}`)
  }

  const getPage = async () => {
    let res = await getTopTraders()

    setProfitedTraders(res.most_profited_traders)
  }

  useEffect(() => {
    getPage()
  }, [])

  return (
    <div className="block p-00 bigmobile:p-4 mdscreen:p-8 mx-auto laptop:w-[68rem] mdscreen:ml-80 desktop:mr-80">
      <SearchBoxHeader
        handleSearch={handleSearch}
        header="Top Traders"
        placeHolder="Search a trader ex. 0xabcdef..."
        explanation={""}
        expMobile={""}
      />
      {
        mostProfitedTraders.length > 0 ?
          <>
            <Table
              header="Most Profited Traders"
              data={mostProfitedTraders}
              columns={[
                "address",
                "total_eth_profit",
                "trade_result_count",
                "success_count",
              ]}
              columnHeaders={["Trader", "Profit", "Traded Shares", "Success Count"]}
              columnViewers={[viewSelf, viewEther, viewSelf, viewSelf]}
              itemName="trader"
              handleVisit={handleVisit}
              viewMore
              to={"/mostprofited"}
            />
          </>
          :
          <WaitingComponent />
      }

    </div>
  )
}

export default TopTraders
