import React, { ReactNode, useRef, useState } from "react"
import BackIcon from "../../utils/images/back.png"
import NextIcon from "../../utils/images/next.png"

interface I_PROPS {
  components: ReactNode[]
  headers: string[]
}

export default function HorizontalScrollNavigation({
  components,
  headers,
}: I_PROPS) {
  const [currentGraphIndex, setCurrentGraphIndex] = useState(0)
  const graphNavigationRef = useRef<HTMLUListElement>(null)

  return (
    <nav className="flex flex-row relative items-center justify-center gap-5">
      {currentGraphIndex > 0 && (
        <button
          className="mb-8"
          key="back"
          onClick={() => {
            if (!graphNavigationRef.current) return
            setCurrentGraphIndex((prev) => prev - 1)
            graphNavigationRef.current.scrollLeft =
              graphNavigationRef.current.scrollLeft - 900
          }}
        >
          <img src={BackIcon} alt="back-icon" />
        </button>
      )}
      <ul
        ref={graphNavigationRef}
        className="flex flex-row overflow-x-hidden w-[900px] scroll-smooth mt-5 w-fit h-fit mx-auto"
      >
        <div className="mt-5 w-fit h-fit mx-auto">
          {components.map((component, index) => (
            <li className="w-full" data-graphIndex={index}>
              <h1 className="ml-6 font-bold text-xl">{headers[index]}</h1>
              {component}
            </li>
          ))}
        </div>
      </ul>

      {/* {currentGraphIndex !== headers.length - 1 && (
        <button
          className="mb-8"
          key="next"
          onClick={() => {
            if (!graphNavigationRef.current) return

            setCurrentGraphIndex((prev) => prev + 1)

            graphNavigationRef.current.scrollLeft =
              graphNavigationRef.current.scrollLeft + 900
          }}
        >
          <img src={NextIcon} alt="next-icon" />
        </button>
      )} */}
    </nav>
  )
}
