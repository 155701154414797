import SearchBoxHeader from "../SearchBoxHeader"
import ProtocolChart from "../ProtocolChart"
import getProtocol from "../../requests/getProtocol"
import { useEffect, useState } from "react"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { EtherIcon } from "../../utils/icons"
import { ethers } from "ethers"
import { gweiToPrettyEth } from "../../utils/helper"
import ethLogo from "../../utils/images/ethlogo.png"
import { useNavigate } from "react-router-dom"
import { supplyToEthPrice } from "../../utils/helper"
import { viewSelf, viewEther, viewSupplyPrice } from "../../utils/helper"
import { viewAddress } from "../../utils/Viewers"
import Table from "../Table"
import VerticalInfo from "../VerticalInfo"
import WaitingComponent from "../WaitingComponent"

const ProtocolProfile = () => {
  let emptyProtocol = {
    curr_block: 0,
    history_blocks: [],
    history_ether: [],
    id: 1,
    total_eth_in: "0",
  }

  const [protocol, setProtocol] = useState<Protocol>(emptyProtocol)
  const [protocolEarning, setProtocolEarning] = useState("0")
  const [dayVolume, setDayVolume] = useState("0")
  const [valShares, setValShares] = useState<ShareItem[]>([])
  const [topTraders, setTopTraders] = useState<TraderItem[]>([])

  const [graphWidth, setGRaphWidth] = useState(1000)
  const [graphHeight, setGRaphHeight] = useState(500)

  const { width, height } = useWindowDimensions()

  const navigate = useNavigate()

  const handleVisit = (e: any, itemAddress: string, itemUrl: string) => {
    e.preventDefault()

    navigate(`/${itemUrl}/${itemAddress}`)
  }

  const handleGetProtocol = async () => {
    try {
      let res = await getProtocol()
      console.log("Protocol:  ", res)
      setProtocol(res.result)
      setProtocolEarning(res.protocol_earning.sum)
      setDayVolume(res.day_volume.sum)
      setValShares(res.most_val_shares)
      setTopTraders(res.top_traders)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    handleGetProtocol()
  }, [])

  const handleSearch = async (shareAddress: string) => {
    let lowerAddress = shareAddress.toLowerCase()

    if (!ethers.utils.isAddress(lowerAddress)) {
      console.log("not address")
      return
    }

    navigate(`/share/${lowerAddress}`)
  }

  useEffect(() => {
    if (width > 1415) {
      setGRaphWidth(600)
      setGRaphHeight(300)
    } else if (width > 750) {
      setGRaphWidth(600)
      setGRaphHeight(300)
    } else if (width > 470) {
      setGRaphWidth(400)
      setGRaphHeight(300)
    } else {
      setGRaphWidth(330)
      setGRaphHeight(300)
    }
  }, [width])

  return (
    <div className="block p-00 bigmobile:p-4 mdscreen:p-8 mx-auto laptop:w-[68rem] tablet:ml-80 desktop:mr-80">
      <SearchBoxHeader
        handleSearch={handleSearch}
        header="Turtle Shack"
        placeHolder="Search for a share"
        explanation={""}
        expMobile={""}
      />
      {
        protocol.curr_block > 0 ?
          <>
            <div className="border mt-5 mx-auto p-2 laptop:p-4 shadow-lg rounded-lg w-full">
              <div className="mx-auto rounded-lg flex flex-col w-fit laptop:flex-row justify-between">
                <div className="flex flex-col justify-around w-full p-4 tracking-wide font-mono">
                  <div className="border-b w-100 flex justify-between items-center h-full py-4">
                    <div className="text-lg font-mono font-normal text-gray-500">
                      Total ETH In
                    </div>
                    <div className="text-xl flex items-center gap-x-1.5">
                      {gweiToPrettyEth(protocol.total_eth_in)}
                      <div className="w-4 h-fit">
                        <img src={ethLogo} />
                      </div>
                    </div>
                  </div>
                  <div className="border-b w-100 flex justify-between items-center h-full py-4">
                    <div className="text-lg font-mono font-normal text-gray-500 mr-5">
                      Last Finalized Block
                    </div>
                    <div className="text-xl flex items-center gap-x-1.5">
                      {protocol.curr_block
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                  <div className="border-b w-100 flex justify-between items-center h-full py-4">
                    <div className="text-lg font-mono font-normal text-gray-500">
                      Protocol Earning
                    </div>
                    <div className="text-xl flex items-center gap-x-1.5">
                      {gweiToPrettyEth(protocolEarning)}
                      <div className="w-4 h-fit">
                        <img src={ethLogo} />
                      </div>
                    </div>
                  </div>
                  <div className="border-b w-100 flex justify-between items-center h-full py-4">
                    <div className="text-lg font-mono font-normal text-gray-500">
                      24H Volume
                    </div>
                    <div className="text-xl flex items-center gap-x-1.5">
                      {gweiToPrettyEth(dayVolume)}
                      <div className="w-4 h-fit">
                        <img src={ethLogo} />
                      </div>
                    </div>
                  </div>
                </div>
                <ProtocolChart
                  width={graphWidth}
                  height={graphHeight}
                  historyBlocks={protocol.history_blocks}
                  historyEther={protocol.history_ether}
                />
              </div>
            </div>

            <VerticalInfo />

            <Table
              header="Most Valueable Shares"
              data={valShares}
              columns={["address", "supply", "ether_in", "trade_count"]}
              columnHeaders={["Share", "Price", "Ether In", "Trades"]}
              columnViewers={[viewSelf, viewSupplyPrice, viewEther, viewSelf]}
              itemName="share"
              handleVisit={handleVisit}
              viewMore
              to={"/mostvalshares"}
            />
            <Table
              header="Top Traders"
              data={topTraders}
              columns={[
                "address",
                "total_eth_profit",
                "trade_result_count",
                "success_count",
              ]}
              columnHeaders={["Trader", "Profit", "Traded Shares", "Success Count"]}
              columnViewers={[viewSelf, viewEther, viewSelf, viewSelf]}
              itemName="trader"
              handleVisit={handleVisit}
              viewMore
              to={"/mostprofited"}
            />
          </>
          :
          <WaitingComponent
            message="loading ..."
          />
      }

    </div>
  )
}

export default ProtocolProfile
