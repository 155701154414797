import Searchbox from "../Searchbox"
import HamburgerMenu from "../HamburgerMenu"

type SearchBoxHeaderProps = {
  handleSearch: any
  header: string
  placeHolder?: string
  explanation: string
  expMobile: string
}

const SearchBoxHeader = ({
  handleSearch,
  header,
  placeHolder = "",
  explanation,
  expMobile,
}: SearchBoxHeaderProps) => {
  return (
    <div className="border-b-2 mx-auto w-full laptop:w-full">
      <HamburgerMenu />
      <div className="grid grid-cols-9 gap-2 items-end py-7">
        <div className="block col-span-9 font-mono font-bold text-3xl">
          {header}
        </div>
        <div className="col-span-9">
          <Searchbox handleSearch={handleSearch} placeHolder={placeHolder} />
        </div>
      </div>
      <div className="text-md justify-between flex font-mono font-bold laptop:text-xl">
        <div className="laptop:hidden">{header}</div>
        <div className="hidden bigmobile:block">{explanation}</div>
        <div className=" bigmobile:hidden">{expMobile}</div>
      </div>
    </div>
  )
}

export default SearchBoxHeader
