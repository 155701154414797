import axios from "axios"
import ENDPOINT from "../utils/Endpoint"

const getTrader = async (address: string) => {
  try {
    let res = await axios.get(ENDPOINT + "trader/" + address)

    console.log(res.data)

    return res.data
  } catch (error) {
    throw new Error("Error fetching data from the API")
  }
}

export default getTrader
