interface I_PROPS {
  keys: string[]
  values: string[]
}

export default function Tooltip({ keys, values }: I_PROPS) {
  return (
    <ul className="flex flex-col gap-2 w-fit bg-purple-600 text-white p-4 rounded-lg font-semibold">
      {keys.map((key, index) => {
        return (
          <li className="block bigmobile:inline-flex gap-4">
            <p className="">{key}:</p>
            <p className="hidden bigmobile:block">{values[index]}</p>
            <p className="block bigmobile:hidden">{values[index].slice(0, 12) + "..." + values[index].slice(-12)}</p>
          </li>
        )
      })}
    </ul>
  )
}
