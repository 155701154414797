import { useEffect, useState } from "react"
import TradeResultChart from "../Charts/TradeResult/SellBuyGraph/TradeResultChart"
import TradeResultValuation from "../Charts/TradeResult/ValuationGraph/TradeResultValuation"
import getTradeResult from "../../requests/getTradeResult"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { useParams, useNavigate } from "react-router-dom"
import { getPrice } from "../../utils/helper"
import VerticalInfo from "../VerticalInfo"
import Table from "../Table"
import { viewSelf, viewSmallEther } from "../../utils/helper"
import HorizontalScrollNavigation from "../HorizontalScrollNavigation"
import PageHeader from "../PageHeader"
import WaitingComponent from "../WaitingComponent"

const TradeResultProfile = () => {
  const emptyTradeResult: TradeResult = {
    ether_in: "",
    ether_out: "",
    history_blocks: [],
    share_history_supply: [],
    share_history_blocks: [],
    history_amount: [],
    id: "",
    subject: "",
    supply: 0,
    trader_address: "",
  }

  let emptyShare = {
    address: "",
    ether_in: "",
    history_blocks: [],
    history_supply: [],
    supply: 0,
    trade_count: 0,
    trader_count: 0,
    volume: "",
  }

  const [tradeResult, setTradeResult] = useState<TradeResult>(emptyTradeResult)
  const [share, setShare] = useState<Share>(emptyShare)
  const [trades, setTrades] = useState<Trade[]>([])

  const [graphWidth, setGRaphWidth] = useState(1000)
  const [graphHeight, setGRaphHeight] = useState(500)

  const { width, height } = useWindowDimensions()

  const { id, shareaddress } = useParams()
  const navigate = useNavigate()

  const viewIsBuy = (isBuy: boolean) => {
    if (isBuy) {
      return <p className="text-green-500">Buy</p>
    } else {
      return <p className="text-red-500">Sell</p>
    }
  }

  const handleGetTradeResultInfo = async (id: string, shareAddress: string) => {
    try {
      let res = await getTradeResult(id, shareAddress)

      console.log("RES TRADE REs: ", res)

      setTradeResult(res.result || emptyTradeResult)
      setShare(res.share || emptyShare)
      setTrades(res.trades || [])

      console.log("Trades:  ", res.trades)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetTradeResultInfo(id || "", shareaddress || "")
  }, [])

  function getSlicedHistoryBlocks() {
    if (!trades || !trades.length) return []

    const historyBlocks = share.history_blocks

    let finalShareAmount = 0
    trades.forEach(({ is_buy, share_amount }) => {
      is_buy
        ? (finalShareAmount += share_amount)
        : (finalShareAmount -= share_amount)
    })

    const tradeStartBlock = trades[0].block_number
    const tradeEndBlock = trades[trades.length - 1].block_number

    const startSliceIndex = historyBlocks.indexOf(tradeStartBlock)
    const endSliceIndex =
      finalShareAmount > 0
        ? historyBlocks.length - 1
        : historyBlocks.indexOf(tradeEndBlock)

    return historyBlocks.slice(startSliceIndex, endSliceIndex)
  }

  useEffect(() => {
    if (width > 1415) {
      setGRaphWidth(1000)
      setGRaphHeight(500)
    } else if (width > 1200) {
      setGRaphWidth(800)
      setGRaphHeight(400)
    } else if (width > 750) {
      setGRaphWidth(600)
      setGRaphHeight(400)
    } else if (width > 470) {
      setGRaphWidth(400)
      setGRaphHeight(300)
    } else {
      setGRaphWidth(330)
      setGRaphHeight(300)
    }
  }, [width])

  const graphs = [
    <TradeResultChart
      width={graphWidth}
      height={graphHeight}
      historyBlocks={share.history_blocks}
      historyProfit={share.history_supply.map((s) => getPrice(s, 1).toString())}
      traderHistory={tradeResult.history_blocks}
      // traderBuyValue={tradeResult.history_supply.map(s => parseFloat(ethers.utils.formatUnits(getPrice(s, 1).toString(), 9)))}
      trades={trades}
    />,
    <TradeResultValuation
      width={graphWidth}
      height={graphHeight}
      historyBlocks={getSlicedHistoryBlocks()}
      historyProfit={share.history_supply.map((s) => getPrice(s, 1).toString())}
      traderHistory={tradeResult.history_blocks}
      trades={trades}
    />,
  ]
  const graphNames = ["Trade Result Chart", "Trade Result Valuation Chart"]

  return (
    <div className="block p-00 bigmobile:p-4 mdscreen:p-8 mx-auto laptop:w-[68rem] mdscreen:ml-80 desktop:mr-80">
      {
        share.address ?
          <>
            <PageHeader
              headerInformationKeys={["Trader Address", "Share Address"]}
              headerInformationValues={[
                tradeResult.trader_address,
                tradeResult.subject,
              ]}
            />
            <HorizontalScrollNavigation components={graphs} headers={graphNames} />

            <Table
              header="Trades"
              data={trades.slice().reverse()}
              columns={["block_number", "share_amount", "ether_amount", "is_buy"]}
              columnHeaders={["Block Number", "Share Amount", "Eth Amount", "Is Buy"]}
              columnViewers={[viewSelf, viewSelf, viewSmallEther, viewIsBuy]}
              itemName="trade"
              handleVisit={(e: any, col0: any, itemName: string, i: number) => {
                console.log(itemName);
                console.log(col0);
                console.log(e.target)
                window.open('https://basescan.org/tx/' + trades[trades.length - i - 1].hash)
              }}
            />
            <VerticalInfo />
          </>
          :
          <WaitingComponent />
      }

    </div>
  )
}

export default TradeResultProfile
