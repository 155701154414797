import { ethers } from "ethers"

export const ETHER_GWEI = 10 ** 9

export const getPrice = (supply: number, amount: number) => {
  let sum1 =
    supply == 0 ? 0 : ((supply - 1) * supply * (2 * (supply - 1) + 1)) / 6
  let sum2 =
    supply == 0 && amount == 1
      ? 0
      : ((supply - 1 + amount) *
          (supply + amount) *
          (2 * (supply - 1 + amount) + 1)) /
        6
  let summation = sum2 - sum1

  return (summation * ETHER_GWEI) / 16000
}

export const getBuyPrice = (supply: number, amount: number) => {
  return getPrice(supply, amount)
}

export const getSellPrice = (supply: number, amount: number) => {
  return getPrice(supply - amount, amount)
}

const protocolFeePercent = 5 / 100
const subjectFeePercent = 5 / 100

export const getBuyPriceAfterFee = (supply: number, amount: number) => {
  let price = getBuyPrice(supply, amount)
  let protocolFee = price * protocolFeePercent
  let subjectFee = (price * subjectFeePercent) / ETHER_GWEI
  return price + protocolFee + subjectFee
}

export const getSellPriceAfterFee = (supply: number, amount: number) => {
  let price = getSellPrice(supply, amount)
  let protocolFee = price * protocolFeePercent
  let subjectFee = price * subjectFeePercent
  return price - protocolFee - subjectFee
}

export const supplyToEthPrice = (supply: number) => {
  return parseFloat(ethers.utils.formatUnits(getPrice(supply, 1).toString(), 9))
}

export const gweiToPrettyEth = (gwei: string) => {
  return parseInt(ethers.utils.formatUnits(gwei, 9))
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const viewEther = (ether: string) => {
  return parseFloat(ethers.utils.formatUnits(ether, 9)).toFixed(2)
}

export const viewSelf = (val: any) => {
  return val
}

export const viewSupplyPrice = (supply: number) => {
  return parseFloat(supplyToEthPrice(supply).toString()).toFixed(2)
}

export const viewSmallEther = (ether: string) => {
  return parseFloat(ethers.utils.formatUnits(ether, 9)).toFixed(4)
}
