import axios from "axios"
import ENDPOINT from "../utils/Endpoint"

async function getMostProfitedTraders(count: number, offset: number) {
  try {
    let res = await axios.get(
      ENDPOINT + "mostprofited/" + count.toString() + "/" + offset.toString()
    )

    return res.data
  } catch (error) {
    throw new Error("Error fetching data from the API")
  }
}

export default getMostProfitedTraders
