const ContactUs = () => {
  return (
    <div className="p-4 text-center font-mono shadow-2xl border-green-600 rounded-lg aspect-[4/3]">
      <div className="font-bold text-2xl text-green-800 mt-10">Email Us</div>
      <div className="mt-5 text-xs">
        Email us for feedback and partnership opportunities.
      </div>
      <div className="font-sans mt-5">contact@turtleshack.xyz</div>
    </div>
  )
}

export default ContactUs
